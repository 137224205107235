@each $prop, $abbrev in (margin: m, padding: p) {
	@each $size, $length in $spacers {
		.#{$abbrev}-#{$size} {
			#{$prop}: $length !important;
		}
		.#{$abbrev}t-#{$size},
		.#{$abbrev}y-#{$size} {
			#{$prop}-top: $length !important;
		}
		.#{$abbrev}r-#{$size},
		.#{$abbrev}x-#{$size} {
			#{$prop}-right: $length !important;
		}
		.#{$abbrev}b-#{$size},
		.#{$abbrev}y-#{$size} {
			#{$prop}-bottom: $length !important;
		}
		.#{$abbrev}l-#{$size},
		.#{$abbrev}x-#{$size} {
			#{$prop}-left: $length !important;
		}
	}
}
