body {
    margin-bottom: 80px;
}

input {
    padding-left: map-get($map: $spacers, $key: 2) !important;
    padding-right: map-get($map: $spacers, $key: 2) !important;
}

nav input#search {
    width: 100% !important;
    box-sizing: border-box;
    padding: 0 50px !important;
}

.tag {
    border: 1px solid color("grey", "lighten-2");
    border-radius: 50rem;
    background-color: color("grey", "lighten-2");
    color: color("grey", "darken-2");
    padding: 2px 10px;
    font-size: .9rem;
    margin-bottom: 5px;
    display: inline-block;
    &:hover {
        cursor: pointer;
        border-color: color("blue", "lighten-2");
    }
    &.active {
        background-color: color("blue", "lighten-2");
        color: #fff;
        &:hover {
            border-color: color("grey", "base");
        }
    }
}

.link-box {
    border: 1px solid color("grey", "base");
    border-radius: $border-radius-lg;
    margin-bottom: 10px;
    padding: 10px;
    position: relative;
    z-index: 1;
}

.markdown-body, .editor-preview {
    ul {
        padding-left: 40px !important;
    }
    ul, ul > li {
        list-style-type: disc !important;
    }
    ul ul, ol ul, ul ul > li, ol ul > li {
        list-style-type: circle !important;
    }
    ol ol ul, ol ul ul, ul ol ul, ul ul ul,
    ol ol ul li, ol ul ul li, ul ol ul li, ul ul ul li {
        list-style-type: square !important;
    }
}